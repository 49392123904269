import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
// import a from "../../components/script";
import Modal from "../../components/modal"

const Payment = () => {
  const location = useLocation()
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(true)
  }, [])

  useEffect(() => {
    document.title = 'Payment | Buy Cashtraq(Ideal)'
  }, [])

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Order Placed" />
      <div className="pb-10">
        <p>Your order has been placed. Thank you!</p>
        <p>Order Id : {location?.state?.transaction_id}</p>
        <p>Order Value : {location?.state?.amount}</p>
        <Link to="/">
          <button className="w-52 h-10 bg-primeColor text-white text-lg mt-4 hover:bg-black duration-300">
            Explore More
          </button>
        </Link>
        <Modal setShowModal={setShowModal} showModal={showModal} />

      </div>
    </div>
  );
};

export default Payment;
