import React from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";

const ProductInfo = ({ productInfo }) => {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col gap-5">
      <h2 className="text-4xl font-semibold">{productInfo.productName}</h2>
      <p className="text-xl font-semibold">₹{productInfo.price}</p>
      <p className="text-base text-gray-600">{productInfo.des}</p>
      <p className="text-sm">Be the first to leave a review.</p>
      <p className="font-medium text-lg">
        <span className="font-normal">Colors:</span> {productInfo.color}
      </p>
      <button
        onClick={() => {
          dispatch(
            addToCart({
              _id: productInfo?.id || productInfo?._id,
              name: productInfo.productName,
              quantity: 1,
              image: productInfo.img,
              badge: productInfo.badge,
              price: productInfo.price,
              colors: productInfo.color,
              item_id: productInfo?.id || productInfo?._id,
              item_name: productInfo?.productName,
              discount: 0,
              index: 0,
              item_variant: productInfo?.color,
              location_id: "ChIJIQBpAG278ahYAR_6128GcTUEo",
            })
          )
          window.dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
              currency: "INR",
              value: productInfo.price,
              items: [
                {
                  item_id: productInfo?.id || productInfo?._id,
                  item_name: productInfo?.productName,
                  discount: 0,
                  index: 0,
                  item_variant: productInfo?.color,
                  location_id: "ChIJIQBpAG278ahYAR_6128GcTUEo",
                  price: productInfo?.price,
                  quantity: 1
                }
              ]
            }
          });

        }

        }
        className="w-full py-4 bg-primeColor hover:bg-black duration-300 text-white text-lg font-titleFont"
      >
        Add to cart
      </button>
      <p className="font-normal text-sm">
        <span className="text-base font-medium"> Categories:</span> Spring
        collection, Streetwear, Women Tags: featured SKU: N/A
      </p>
    </div>
  );
};

export default ProductInfo;
